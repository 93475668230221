@font-face {
  font-family: "SF PRO DISPLAY";
  src: url("../public/fonts/SF-Pro-Display-Regular.ttf");
  font-style: normal; /* Adjust if needed */
}

body {
  margin: 0;
  background-color: #f8f8f8 !important;
  color: #444a6d;
  font-family: "SF PRO DISPLAY";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit !important;
}

/* scrollbar */
body::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  /* display: none; */
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff6600;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff6600;
}

.loginbackground_img {
  background: url("./assets/images/login_bg.svg") no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.background_img {
  background-image: url(./assets/images/client_bg.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  width: 100%;
  height: 85%;
  position: fixed;
  right: 1.5%;
  bottom: 35px;
  z-index: -1;
}

.close_icon {
  cursor: pointer;
}

#main-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

input[type="color"] {
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
}

/* Date Picker Center Css */
.MuiPickersPopper-root {
  position: absolute !important;
  inset: 0px auto auto 0px !important;
  margin: 0px !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

@keyframes slideRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes changeOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.1;
  }
}
@keyframes slideRightToLeftRevert {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes changeOpacityRevert {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 0;
  }
}
